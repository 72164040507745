<template>
  <div>
    <div class="space"></div>
    <div class="position-fixed w-100" style="z-index: 1000; top: 0;">
      <Announce></Announce>
      <nav class="navbar navbar-light bg-white py-0">
      <div class="container ">
        <div class="d-flex justify-content-between align-items-center w-100 mt-3 mb-2">
          <div class="w-25 w-md-30 w-lg-30 d-flex align-items-end">
            <button class="w-35 navbar-toggler d-block d-lg-none p-0 mx-1 jump" type="button" @click.prevent="OffcanvasOpen" >
              <span class="navbar-toggler-icon "></span>
            </button>
            <!-- <div  class="w-30 d-block d-lg-none mx-1 mx-md-4 mx-lg-1" >
              <img class="w-100 jump" src="@/assets/img/Icons-24.svg" alt="" >
            </div> -->
          </div>
          <router-link to="/" class="w-40 w-md-30 w-lg-15">
            <img class="" src="@/assets/img/Icons-02.svg" alt="logo">
          </router-link>
          <div class="w-25 w-md-30 w-lg-30 d-flex  align-items-center  ">
            <ul class="list-unstyled d-flex flex-row-reverse flex-lg-row justify-content-center align-items-center  w-100 m-0 pt-2 " >
              <li class="w-10 me-1 d-none d-lg-block ">
                <!-- 免運車 -->
                <img  class="w-100 jump" src="@/assets/img/Icons-01.svg" alt="">
              </li>
              <li class="w-lg-25 w-20 fs-6 me-1 text-primary d-none d-lg-block me-lg-0 me-xl-1">
                全館免運
              </li>
              <li class="w-30 w-lg-10 ms-1 ms-md-4 ms-lg-0 px-md-1">
                <!-- 會員圖示 -->
                <router-link to="/registerlogin" v-if="!$store.state.isLogin">
                  <img  class="w-100 " src="@/assets/img/Icons-03.svg" alt="">
                </router-link>
                <router-link to="/memberupdateinfo" v-else>
                  <img  class="w-100 " src="@/assets/img/Icons-37.svg" alt="">
                </router-link>
              </li>
              <li class=" d-none d-lg-block" >
                <router-link href="#" v-if="!$store.state.isLogin" to="/registerlogin" class="text-bottom" @click="goLogin">登入/</router-link>
                <router-link href="#" v-else to="/memberupdateinfo" class="">會員中心  /</router-link>
              </li>
              <li class=" d-none d-lg-block ms-1">
                <router-link href="#" v-if="!$store.state.isLogin" to="/registerlogin" class="text-bottom" @click="goRegister">註冊</router-link>
                <a href="#" v-else @click.prevent="logOut" class="">登出</a>
              </li>
              <li class="w-30 w-lg-10 me-3 me-md-0 ms-md-1 px-md-1 position-relative">
                <!-- 購物車 -->
                <a href="#" class="d-block" @click.prevent="toCheckoutCart">
                  <img  class="w-100 " src="@/assets/img/Icons-04.svg" alt="">
                  <div v-if="this.$store.state.cartNum !== 0" class="cartNum position-absolute bg-danger text-white rounded-circle text-center">{{ this.$store.state.cartNum }}</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- ===手機版menu== -->
        <div class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="offcanvasExampleLabel"  ref="canvasDom">
          <div class="offcanvas-header bg-primary text-white">
            <div  class="w-25"><img src="@/assets/img/Icons-36.svg" alt=""></div>
            <h3 class="offcanvas-title fs-10" id="offcanvasExampleLabel">全館免運</h3>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body  ">
            <!-- <div class="d-flex  position-relative">
              <input class="form-control search px-md-2 py-md-3" type="search" placeholder="找商品" style="">
              <a class=" position-absolute w-10  center" >
                <img class="" src="../assets/img/Icons-05.svg" alt="search button" />
              </a>
              </div> -->
              <div class="accordion accordion-flush" id="accordion-one">
                <div class="accordion-item ">
                  <h5 class="accordion-header " id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#newes" >
                      <p class="fs-10 ">最新消息</p>
                    </button>
                  </h5>
                  <div id="newes" class="accordion-collapse collapse show" data-bs-parent="#accordion-one">
                    <div class="accordion-body">
                      <ul class="ul-unstyled">
                        <li class="pt-3 pb-4">
                          <router-link class="fs-10 " to="/promoteeventlist" @click.prevent="hideCanvas">
                            優惠活動
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 " to="/neweventlist" @click.prevent="hideCanvas">
                            最新消息
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 " to="/seasoneventlist" @click.prevent="hideCanvas">
                            季節限定
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button collapsed after-display" type="button" data-bs-toggle="collapse" data-bs-target="#productSeries" >
                      <router-link  to="/productlist" @click.prevent="hideCanvas">
                        <p class="fs-10 ">全系列商品</p>
                      </router-link>
                    </button>
                  </h5>
                  <!-- <div id="productSeries" class="accordion-collapse collapse" >
                    <div class="accordion-body">
                      <ul class="ul-unstyled">
                        <li class="pt-3 pb-4">
                          <router-link class="fs-10 papa-hover" to="/">
                            優惠活動
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/">
                          最新消息
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/">
                          季節限定
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
                <div v-if="!isExpired" class="accordion-item">
                  <h5 class="accordion-header" id="double11Event">
                    <button class="accordion-button collapsed after-display" type="button">
                      <router-link  to="/double11Event" @click.prevent="hideCanvas">
                        <p class="fs-10 ">1+1驚喜價</p>
                      </router-link>
                    </button>
                  </h5>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#aboutUs" >
                      <p class="fs-10">關於我們</p>
                    </button>
                  </h5>
                  <div id="aboutUs" class="accordion-collapse collapse" data-bs-parent="#accordion-one">
                    <div class="accordion-body">
                      <ul class="ul-unstyled">
                        <li class="pt-3 pb-4">
                          <router-link class="fs-10 papa-hover" to="/aboutus" @click.prevent="hideCanvas">
                            認識Papawash
                          </router-link>
                        </li>
                        <!-- <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/" @click.prevent="hideCanvas">
                            品牌精神
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/" @click.prevent="hideCanvas">
                            線上肌膚檢測
                          </router-link>
                        </li> -->
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/papawashQA" @click.prevent="hideCanvas">
                            Papawash Q&A
                          </router-link>
                        </li>
                        <!-- <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/" @click.prevent="hideCanvas">
                            洗顏方法
                          </router-link>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button collapsed rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#memberCenter" >
                      <p class="fs-10 ">會員中心</p>
                    </button>
                  </h5>
                  <div id="memberCenter" class="accordion-collapse collapse" data-bs-parent="#accordion-one">
                    <div class="accordion-body">
                      <ul class="ul-unstyled">
                        <li class="pt-3 pb-4">
                          <router-link class="fs-10 papa-hover" to="/memberorder"  @click.prevent="hideCanvas">
                            訂單資訊
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/memberupdateinfo" @click.prevent="hideCanvas">
                            個人帳戶管理
                          </router-link>
                        </li>
                        <!-- <li class="pb-4">
                            我的優惠券
                        </li> -->
                        <!-- <li class="pb-4">
                          <router-link class="ms-4 fs-10 papa-hover" to="/" @click.prevent="hideCanvas">
                            抵用券
                          </router-link>
                        </li> -->
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/memberfeedback" @click.prevent="hideCanvas">
                            點數
                          </router-link>
                        </li>
                        <li class="pb-4">
                          <router-link class="fs-10 papa-hover" to="/contactus" @click.prevent="hideCanvas">
                            聯絡客服
                          </router-link>
                        </li>
                        <li v-if="$store.state.isLogin" class="pb-4">
                          <a class="fs-10 papa-hover"  @click.prevent="hideCanvasLogOut">
                            會員登出
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <Category class="d-none d-lg-block"></Category>
      </nav>
    </div>
  </div>
</template>

<script >
import Announce from '@/components/Announce.vue'
import Category from '@/components/Category.vue'
// import Menu from '@/components/Menu.vue'
import CartSvc from '@/service/cart-service.js'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import moment from 'moment'

export default ({
  data () {
    return {
      cartNum: 0,
      Offcanvas: '',
      isExpired: false
    }
  },
  components: {
    Announce,
    Category
    // Menu
  },
  watch: {

  },
  methods: {
    //* 檢查是否有cookie
    checkCookie () {
      const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (AccessToken || RefreshToken) {
        return true
      } else {
        return false
      }
    },
    toCheckoutCart () {
      if (this.checkCookie()) {
        this.$router.push('/checkoutboard/checkoutcart')
      } else {
        this.$swal.fire({
          title: '請先登入',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('refreshClickCart', true)
            this.$router.push('/registerlogin')
          }
        })
      }
    },
    logOut () {
      this.$swal.fire({
        title: '是否要登出?',
        allowOutsideClick: true,
        confirmButtonColor: '#003894',
        confirmButtonText: '確認',
        width: 400,
        customClass: {
          title: 'text-class',
          confirmButton: 'confirm-btn-class'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.checkCookie()) {
            const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
            const url = `${process.env.VUE_APP_API}/api/members/Logout`
            this.axios.get(url, {
              headers: { Authorization: `Bearer ${RefreshToken}` }
            }).then(res => {
              if (res.data.rtnCode === 0) {
                this.$store.dispatch('logoutRemoveCookie')
                this.$store.commit('changeLoginState', false)
                this.$store.commit('getcartNum', 0)
                this.$router.push('/')
              } else {
                this.$swal.fire({
                  title: `${res.data.rtnMsg}`,
                  allowOutsideClick: true,
                  confirmButtonColor: '#003894',
                  confirmButtonText: '確認',
                  width: 400,
                  customClass: {
                    title: 'text-class',
                    confirmButton: 'confirm-btn-class'
                  }
                })
              }
            })
          }
        }
      })
    },
    hideCanvas () {
      this.Offcanvas.hide()
    },
    OffcanvasOpen () {
      this.Offcanvas.show()
    },
    hideCanvasLogOut () {
      this.Offcanvas.hide()
      this.logOut()
    },
    goRegister () {
      this.$store.commit('refreshloginState', 'register')
    },
    goLogin () {
      this.$store.commit('refreshloginState', 'login')
    }
  },
  mounted () {
    if (this.checkCookie()) {
      CartSvc.GetCart()
      this.$store.commit('changeLoginState', true)
    }
    this.Offcanvas = new bootstrap.Offcanvas(this.$refs.canvasDom)

    // ? 1+1 驚喜價活動是否已過期
    const now = moment().format('YYYY/MM/DD HH:mm:ss')
    if (moment(now, 'YYYY/MM/DD HH:mm:ss').isAfter('2022/11/30 23:59:59')) {
      this.isExpired = true
    }
  }
})
</script>

<style lang="scss" scoped>
  //
</style>
